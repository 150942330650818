<template>
	<loading></loading>
</template>

<script>
	
	import Loading from "../Layout/Loading";
	export default{
		name: 'Main'
		, components: {Loading}
		, data: function(){
			return {

			}	
		}
		
		,created: function(){
			this.$router.push({ name: 'Index'})
		}
	}
	
</script>